define('ember-parachute/initializers/ember-parachute', ['exports', 'ember-parachute/query-params', 'ember-parachute/-private/parachute-event', 'ember-parachute/utils/lookup-controller'], function (exports, _queryParams, _parachuteEvent, _lookupController) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  var RSVP = Ember.RSVP,
      run = Ember.run,
      assign = Ember.assign,
      canInvoke = Ember.canInvoke,
      tryInvoke = Ember.tryInvoke,
      sendEvent = Ember.sendEvent;
  var keys = Object.keys;
  function initialize() /* application */{
    if (Ember.Route._didInitializeParachute) {
      return;
    }

    Ember.Route.reopen({
      setupController: function setupController(controller, model, transition) {
        this._super.apply(this, arguments);

        if (_queryParams.default.hasParachute(controller)) {
          this._setupParachuteQueryParamsMap(controller);

          var routeName = this.routeName;

          var event = new _parachuteEvent.default(routeName, controller, {});

          // Overrides
          event.changed = event.changes;
          event.shouldRefresh = true;

          tryInvoke(controller, 'setup', [event, transition]);
          sendEvent(controller, 'setup', [event, transition]);
        }
      },
      resetController: function resetController(controller, isExiting) {
        this._super.apply(this, arguments);

        if (_queryParams.default.hasParachute(controller)) {
          var routeName = this.routeName;

          var event = new _parachuteEvent.default(routeName, controller, {});

          // Overrides
          event.shouldRefresh = false;

          tryInvoke(controller, 'reset', [event, isExiting]);
          sendEvent(controller, 'reset', [event, isExiting]);
        }
      },
      deserialize: function deserialize(params, transition) {
        // Check if handlers have already been loaded.
        // If so, don't return a promise as it will result in
        // the loading screen/state flashing.
        if (!transition.handlerInfos.find(function (x) {
          return !x.handler;
        })) {
          return this._super(params, transition);
        }

        // Save and bind the refence to the super here
        // as this._super doesn't work in callbacks
        // https://github.com/emberjs/ember.js/issues/15291
        var actualSuper = this._super.bind(this);

        return RSVP.all(transition.handlerInfos.map(function (x) {
          return x.handlerPromise;
        })).then(function () {
          return actualSuper(params, transition);
        });
      },
      serializeQueryParam: function serializeQueryParam(value, urlKey /**, defaultValueType **/) {
        var controller = (0, _lookupController.default)(this);

        if (_queryParams.default.hasParachute(controller)) {
          var queryParam = _queryParams.default.lookupQueryParam(controller, urlKey);

          if (canInvoke(queryParam, 'serialize')) {
            return queryParam.serialize(value, controller);
          }
        }

        return this._super.apply(this, arguments);
      },
      deserializeQueryParam: function deserializeQueryParam(value, urlKey /**, defaultValueType **/) {
        var controller = (0, _lookupController.default)(this);

        if (_queryParams.default.hasParachute(controller)) {
          var queryParam = _queryParams.default.lookupQueryParam(controller, urlKey);

          if (canInvoke(queryParam, 'deserialize')) {
            return queryParam.deserialize(value, controller);
          }
        }

        return this._super.apply(this, arguments);
      },
      _scheduleParachuteChangeEvent: function _scheduleParachuteChangeEvent(routeName, controller) {
        var changed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

        run.schedule('afterRender', this, function () {
          var event = new _parachuteEvent.default(routeName, controller, changed);

          tryInvoke(controller, 'queryParamsDidChange', [event]);
          sendEvent(controller, 'queryParamsDidChange', [event]);
        });
      },
      _setupParachuteQueryParamsMap: function _setupParachuteQueryParamsMap(controller) {
        if (!this.__hasSetupParachuteQPs) {
          var qpMap = this.get('queryParams');

          var _QueryParams$metaFor = _queryParams.default.metaFor(controller),
              qpMapForRoute = _QueryParams$metaFor.qpMapForRoute;

          keys(qpMapForRoute).forEach(function (key) {
            qpMapForRoute[key] = assign({}, qpMapForRoute[key], qpMap[key]);
          });

          this.set('queryParams', qpMapForRoute);
          this.__hasSetupParachuteQPs = true;
        }
      },


      actions: {
        queryParamsDidChange: function queryParamsDidChange() {
          var changed = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
          var present = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
          var removed = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
          var controller = this.controller,
              routeName = this.routeName;


          if (_queryParams.default.hasParachute(controller)) {
            this._scheduleParachuteChangeEvent(routeName, controller, assign({}, changed, removed));
          }

          return this._super.apply(this, arguments);
        }
      }
    });

    Ember.Route.reopenClass({ _didInitializeParachute: true });
  }

  exports.default = {
    name: 'ember-parachute',
    initialize: initialize
  };
});