define('ember-colpick/mixins/col-pick', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function onRenderObserver() /*keys..., fn*/{
    var args = Array.prototype.slice.call(arguments);
    var fn = args.slice(-1)[0];
    var keys = args.slice(0, -1);

    var observerFunction = function observerFunction() {
      if (this._state !== 'inDOM') {
        // don't schedule unless inDOM
        return;
      }
      Ember.run.schedule('render', this, function () {
        // don't run unless still inDOM
        if (this._state === 'inDOM') {
          fn.call(this);
        }
      });
    };

    return Ember.observer.apply(null, keys.concat([observerFunction]));
  }

  exports.default = Ember.Mixin.create({
    colpickLayout: 'hex',
    colorScheme: 'dark',
    classNames: ['ember-col-pick'],
    flat: true, // [true/false] render as popup (true) rendering inline (false)
    value: null,
    previewValue: null,
    useHashtag: false,

    _colpick: undefined,

    configDidChange: onRenderObserver('colorScheme', 'colpickLayout', 'flat', function () {
      this._tearDownColpick();
      this.rerender();
    }),

    valueDidChange: onRenderObserver('value', function () {
      var value = this.get('value');
      if (this._colpick && value) {
        this._colpick.colpickSetColor(value);
      }
    }),

    _setupColpick: function _setupColpick() {
      var instance = this;
      var layout = this.get('colpickLayout');
      var colorScheme = this.get('colorScheme');

      if (layout && colorScheme) {
        var colpick = this._colpick = this.$().colpick({
          layout: layout,
          colorScheme: colorScheme,
          submit: 0,
          flat: this.get('flat'),
          onChange: Ember.run.bind(this, function (hsb, hex, rgb, el, bySetColor) {
            if (this.get('useHashtag')) {
              hex = '#' + hex;
            }

            this.set('previewValue', hex);

            if (this._isValidPreviewValue() && !bySetColor) {
              this._setColpickValue(hex);
            }
          }),
          onHide: Ember.run.bind(this, function () {
            this.sendAction('onHide');
          })
        });

        colpick.find('input[type=text]').keyup(function () {
          var hexInputVal = this.value;
          if (hexInputVal.length === 6) {
            colpick.colpickSetColor(hexInputVal);
            instance._setColpickValue(hexInputVal);
          }
        });

        var value = this.get('value');
        if (value) {
          colpick.colpickSetColor(value);
        }
      }
    },

    _isValidPreviewValue: function _isValidPreviewValue() {
      var previewHex = this.get('previewValue');
      var validityRegex = this.get('useHashtag') ? /^#[a-f0-9]{6}$/i : /^[a-f0-9]{6}$/i;
      return Ember.isPresent(previewHex.match(validityRegex));
    },

    popup: function popup() {
      if (this._state === 'inDOM') {
        return Ember.$('#' + this.$().data('colpickId'));
      }
    },

    didInsertElement: function didInsertElement() {
      this._super();
      this._setupColpick();
    },

    _setColpickValue: function _setColpickValue(value) {
      this.set('value', value);
      this.sendAction('onChange', value);
    },

    _tearDownColpick: function _tearDownColpick() {
      if (this._colpick) {
        if (!this.isDestroying) {
          this._colpick.colpickDestroy();
        }
        this._colpick = undefined;
      }
    },

    willDestroyElement: function willDestroyElement() {
      this._tearDownColpick();
      this._super();
    }
  });
});