define("ember-modal-service/utils/css-transitions/index", ["exports", "ember-modal-service/utils/css-transitions/on-transition-end", "ember-modal-service/utils/css-transitions/has-transitions"], function (_exports, _onTransitionEnd, _hasTransitions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "onTransitionEnd", {
    enumerable: true,
    get: function get() {
      return _onTransitionEnd.default;
    }
  });
  Object.defineProperty(_exports, "hasTransitions", {
    enumerable: true,
    get: function get() {
      return _hasTransitions.default;
    }
  });
});