define("ember-api-actions/utils/member-action", ["exports", "ember-api-actions/utils/build-url", "ember-api-actions/utils/types"], function (_exports, _buildUrl, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = instanceOp;

  function instanceOp(options) {
    return function runInstanceOp(payload) {
      var _this = this;

      var recordClass = (0, _buildUrl._getModelClass)(this);
      var modelName = (0, _buildUrl._getModelName)(recordClass);
      var store = (0, _buildUrl._getStoreFromRecord)(this);
      var ajaxOptions = options.ajaxOptions,
          path = options.path,
          before = options.before,
          after = options.after,
          _options$type = options.type,
          type = _options$type === void 0 ? 'put' : _options$type,
          _options$urlType = options.urlType,
          urlType = _options$urlType === void 0 ? 'updateRecord' : _options$urlType;
      var requestType = (0, _types.strictifyHttpVerb)(type);
      var adapter = store.adapterFor(modelName);
      var fullUrl = (0, _buildUrl.buildOperationUrl)(this, path, urlType);
      var data = before && before.call(this, payload) || payload;
      return adapter.ajax(fullUrl, requestType, Ember.merge(ajaxOptions || {}, {
        data: data
      })).then(function (response) {
        if (after && !_this.isDestroyed) {
          return after.call(_this, response);
        }

        return response;
      });
    };
  }
});