define("ember-modal-service/components/modal-container", ["exports", "ember-modal-service/templates/components/modal-container"], function (_exports, _modalContainer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component that acts like a container to wrap modal object on its own component and view.
   *
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    layout: _modalContainer.default,

    /**
     * Binded CSS classes.
     *
     * @property classNameBindings
     * @type Array
     */
    classNameBindings: ['fullHeight'],

    /**
     * Show set 100% height.
     *
     * @property fullHeight
     * @type Boolean
     */
    fullHeight: Ember.computed.notEmpty('modal.content'),

    /**
     * Modal service injection.
     *
     * @property modal
     * @type Object
     */
    modal: Ember.inject.service('modal')
  });

  _exports.default = _default;
});