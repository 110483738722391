define('ember-collection/utils/needs-revalidate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = needsRevalidate;
  function needsRevalidate(view) {
    view._renderNode.isDirty = true;
    view._renderNode.ownerNode.emberView.scheduleRevalidate(view._renderNode, view.toString(), 'rerendering via needsRevalidate');
  }
});