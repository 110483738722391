define("ember-modal-service/models/modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Modal object to instantiate modals over controller.
   *
   * @extends Ember.Object
   */
  var _default = Ember.Object.extend({
    /**
     * Name of the modal.
     *
     * @property name
     * @type String
     */
    name: null,

    /**
     * Options of the modal.
     *
     * @property options
     * @type Object
     */
    options: null,

    /**
     * Deferred object.
     *
     * @property deferred
     * @type Object
     */
    deferred: null,

    /**
     * Promise object from deferred.
     *
     * @property promise
     * @type Promise
     */
    promise: Ember.computed.reads('deferred.promise'),

    /**
     * Full name for building controllers and views.
     *
     * @property fullname
     * @type String
     */
    fullname: Ember.computed('name', function () {
      var name = this.get('name');

      if (Ember.isBlank(name)) {
        throw new Error('Modal must have a name.');
      }

      return "modal-".concat(Ember.String.dasherize(name));
    }),

    /**
     * Setup the promise on init.
     *
     * @method init
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.set('deferred', Ember.RSVP.defer("Modal: open '".concat(this.get('fullname'), "'")));
    }
  });

  _exports.default = _default;
});