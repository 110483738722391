define('ember-collection/utils/style-properties', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.styleProperty = styleProperty;
  exports.cssProperty = cssProperty;

  var stylePrefixes = ['webkit', 'Webkit', 'ms', 'Moz', 'O'];
  var cssPrefixes = ['-webkit-', '-ms-', '-moz-', '-o-'];

  var style = typeof document !== 'undefined' && document.documentElement && document.documentElement.style;

  function findProperty(property, css) {
    var prop = css ? Ember.String.camelize(property) : property;
    if (prop in style) {
      return property;
    }
    var capitalized = Ember.String.capitalize(prop);
    for (var i = 0; i < stylePrefixes.length; i++) {
      var prefixed = stylePrefixes[i] + capitalized;
      if (prefixed in style) {
        return css ? cssPrefixes[i] + property : prefixed;
      }
    }
  }

  function styleProperty(prop) {
    return findProperty(prop, false);
  }

  function cssProperty(cssProp) {
    return findProperty(cssProp, true);
  }
});