define("ember-api-actions/utils/collection-action", ["exports", "ember-api-actions/utils/build-url", "ember-api-actions/utils/types"], function (_exports, _buildUrl, _types) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = collectionOp;

  function collectionOp(options) {
    return function runCollectionOp(payload) {
      var model = this;
      var recordClass = (0, _buildUrl._getModelClass)(model);
      var modelName = (0, _buildUrl._getModelName)(recordClass);
      var store = (0, _buildUrl._getStoreFromRecord)(model);
      var requestType = (0, _types.strictifyHttpVerb)(options.type || 'put');
      var urlType = options.urlType || 'updateRecord';
      var adapter = store.adapterFor(modelName);
      var fullUrl = (0, _buildUrl.buildOperationUrl)(model, options.path, urlType, false);
      var data = options.before && options.before.call(model, payload) || payload;
      return adapter.ajax(fullUrl, requestType, Ember.merge(options.ajaxOptions || {}, {
        data: data
      })).then(function (response) {
        if (options.after && !model.isDestroyed) {
          return options.after.call(model, response);
        }

        return response;
      });
    };
  }
});