define('ember-changeset/utils/assign', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = pureAssign;
    function pureAssign() {
        var _Ember;

        for (var _len = arguments.length, objects = Array(_len), _key = 0; _key < _len; _key++) {
            objects[_key] = arguments[_key];
        }

        return (_Ember = Ember).assign.apply(_Ember, [{}].concat(objects));
    }
});