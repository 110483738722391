define("ember-modal-service/components/modal", ["exports", "ember-modal-service/utils/css-transitions"], function (_exports, _cssTransitions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Component to wrap modal objects.
   *
   * @extends Ember.Component
   */
  var _default = Ember.Component.extend({
    /**
     * Modal service inject.
     *
     * @property modal
     * @type Object
     */
    scheduler: Ember.inject.service('scheduler'),

    /**
     * Modal service inject.
     *
     * @property modal
     * @type Object
     */
    modal: Ember.inject.service('modal'),

    /**
     * HTML class name bindings.
     *
     * @property classNameBindings
     * @type Array
     */
    classNameBindings: ['model.options.hasOverlay:modal-overlay:modal-view'],

    /**
     * HTML attributes bindings.
     *
     * @property attributeBindings
     * @type Array
     */
    attributeBindings: ['data-modal-show', 'data-id'],

    /**
     * HTML role.
     *
     * @property ariaRole
     * @type String
     */
    ariaRole: 'dialog',

    /**
     * Modal is visible/hidden.
     *
     * @property visible
     * @type Boolean
     */
    visible: false,

    /**
     * `data-id` attribute of wrapper element
     *
     * @property data-id
     * @type {String}
     */
    'data-id': Ember.computed('model.fullname', function () {
      return Ember.String.camelize(this.get('model.fullname'));
    }),

    /**
     * Modal is visible/hidden. This property is read from CSS.
     *
     * @property data-modal-show
     * @type Boolean
     */
    'data-modal-show': Ember.computed('visible', function () {
      return String(this.get('visible'));
    }),

    /**
     * On did insert element, set element as visible and set data-id.
     *
     * @event onDidInsertElement
     */
    onDidInsertElement: Ember.on('didInsertElement', function () {
      var scheduler = this.get('scheduler');
      Ember.run.next(scheduler.scheduleOnce.bind(scheduler, this, '_open'));
    }),

    /**
     * Resolve current promise and close modal.
     *
     * @method resolve
     */
    resolve: function resolve(data) {
      var label = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Component '".concat(this.get('model.fullname'), "': fulfillment");
      this.get('model.deferred').resolve(data, label);
    },

    /**
     * Reject current promise and close modal.
     *
     * @method reject
     */
    reject: function reject(data) {
      var label = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "Component '".concat(this.get('model.fullname'), "': rejection");
      this.get('model.deferred').reject(data, label);
    },

    /**
     * Action to know when modal is fully opened.
     *
     * @method didOpen
     */
    didOpen: function didOpen() {},

    /**
     * Safe call to didOpen method.
     *
     * @method _safeDidOpen
     */
    _safeDidOpen: function _safeDidOpen() {
      if (this.isDestroyed) {
        return;
      }

      this.didOpen();
    },

    /**
     * Turn on visibility and send didOpen event.
     *
     * @method _open
     * @private
     */
    _open: function _open() {
      if (this.isDestroyed) {
        return;
      }

      var scheduler = this.get('scheduler');
      var element = this.element;
      this.set('visible', true);

      if ((0, _cssTransitions.hasTransitions)(element)) {
        (0, _cssTransitions.onTransitionEnd)(element, scheduler.scheduleOnce.bind(scheduler, this, '_safeDidOpen'), 'all', true);
      } else {
        this.didOpen();
      }
    },

    /**
     * Set modal as not visible and remove modal from array later.
     *
     * @method _close
     * @private
     */
    _close: function _close() {
      if (this.isDestroyed) {
        return;
      }

      var scheduler = this.get('scheduler');
      var element = this.element; // Close modal.

      this.set('visible', false); // Remove modal from array when transition ends.

      if ((0, _cssTransitions.hasTransitions)(element)) {
        (0, _cssTransitions.onTransitionEnd)(element, scheduler.scheduleOnce.bind(scheduler, this, '_remove'), 'all', true);
      } else {
        this._remove();
      }
    },

    /**
     * Remove itself from service.
     *
     * @method _remove
     * @private
     */
    _remove: function _remove() {
      if (this.isDestroyed) {
        return;
      }

      this.get('modal.content').removeObject(this.get('model'));
    },

    /**
     * When the promise has been settled, close the view.
     *
     * @method hasBeenSettled
     * @private
     */
    _hasBeenSettled: Ember.on('init', function () {
      var _this = this;

      // Prevent triggering Ember.onerror on promise resolution.
      this.get('model.promise').catch(function (e) {
        if (e instanceof Error) {
          return Ember.RSVP.reject(e, "Component '".concat(_this.get('model.fullname'), "': bubble error"));
        } // Ignore rejections due to not being real errors here.


        return e;
      }, "Component '".concat(this.get('model.fullname'), "': catch real errors or ignore")).finally(this._close.bind(this), "Component '".concat(this.get('model.fullname'), "': close modal"));
    }),
    actions: {
      /**
       * Action to resolve the underlying modal promise directly from the
       * template, using the passed arguments as resolution values
       *
       * @method resolve
       */
      resolve: function resolve() {
        this.resolve.apply(this, arguments);
      },

      /**
       * Action to reject the underlying modal promise directly from the
       * template, using the passed arguments as rejection values
       *
       * @method reject
       */
      reject: function reject() {
        this.reject.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});