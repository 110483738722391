define("ember-modal-service/utils/css-transitions/has-transitions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = hasTransitions;

  /**
   * Returns whether an element has CSS transitions applied directly on itself.
   *
   * @method hasTransitions
   * @param {Element} element
   * @return Boolean
   */
  function hasTransitions(element) {
    var _window$getComputedSt = window.getComputedStyle(element),
        transitionProperty = _window$getComputedSt.transitionProperty,
        transitionDuration = _window$getComputedSt.transitionDuration;

    var hasNoDurations = transitionDuration.split(', ').every(function (duration) {
      return duration === '0s';
    });
    return !(hasNoDurations || transitionProperty === 'all' && transitionDuration === '0s');
  }
});